import React, { useRef, useState } from 'react';
import { graphql } from 'gatsby';
import PageWrapper from '../components/PageWrapper';
import { Link } from 'gatsby';
import SEO from '../components/SEO';
import SpeedyReader from 'react-speedy-reader';
import { Helmet } from 'react-helmet';
import Commit from '../sections/common/Commit';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const speed = useRef();
  const [isPlaying, setPlaying] = useState();
  const [isShowing, setShow] = useState();
  return (
    <PageWrapper
      themeConfig={{
        headerClassName: 'site-header--menu-left',
        headerFluid: false,
        headerButton: (
          <>
            <Link
              to="/contact"
              className="btn btn btn-dodger-blue-2 btn-medium rounded-5 font-size-3"
            >
              Let's Get Started
            </Link>
          </>
        ),
        footerStyle: 'digma',
      }}
    >
      <Helmet>
        {frontmatter.videoplayer === 'old' && [
          <link
            href={'https://cdn.digma.io/dist/digma.min.1.1.0.css?v=old'}
            rel="stylesheet"
          />,
          <script
            async
            src={'https://cdn.digma.io/dist/digma.min.1.1.0.js?v=old'}
          ></script>,
        ]}
        {frontmatter.videoplayer === 'new' && [
          <link
            href={'https://cdn.digma.io/dist/digma.min.1.2.0.css?v=new'}
            rel="stylesheet"
          />,
          <script
            async
            src={'https://cdn.digma.io/dist/digma.min.1.2.0.js?v=new'}
          ></script>,
        ]}
        {frontmatter.videoplayer === 'cms' && [
          <link
            href={'https://cdn.digma.io/dist/digma.min.2.0.0.css?v=cms'}
            rel="stylesheet"
          />,
          <script
            async
            src={'https://cdn.digma.io/dist/digma.min.2.0.0.js?v=cms'}
          ></script>,
        ]}
      </Helmet>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        image={frontmatter.featureimage.childImageSharp.fluid.src}
      />
      <div className="container pt-20 pt-md-16 pt-lg-20 ">
        <nav className="breadcrumb" aria-label="Breadcrumb">
          <ol className="breadcrumb__list">
            <li className="breadcrumb__item">
              <Link className="breadcrumb__link" to="/">
                Home
              </Link>
              <span className="breadcrumb__separator" aria-hidden="true">
                {' '}
                /{' '}
              </span>
            </li>
            <li className="breadcrumb__item">
              <Link className="breadcrumb__link" to="/info/">
                Info
              </Link>
              <span className="breadcrumb__separator" aria-hidden="true">
                {' '}
                /{' '}
              </span>
            </li>
            <li className="breadcrumb__item">
              <Link className="breadcrumb__link" to="/info/news">
                News
              </Link>
              <span className="breadcrumb__separator" aria-hidden="true">
                {' '}
                /{' '}
              </span>
            </li>
            <li className="breadcrumb__item">
              <Link className="breadcrumb__link" to={frontmatter.slug}>
                {frontmatter.title}
              </Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="container pt-5 pt-lg-5 pb-8 pb-lg-22 ">
        <div className="blog-post-container">
          <div className="blog-post">
            <div
              className="row text-lg-left aos-init aos-animate"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              <h1 className="col-xl-11 font-size-11 pr-md-6 pr-lg-10 pr-xl-33 mb-0">
                {frontmatter.title}
              </h1>
            </div>
            <h2 className="text-bali-gray font-size-7 pr-md-6 pr-lg-9 pr-xl-0 mb-0">
              <time dateTime={frontmatter.date}>{frontmatter.date}</time>
            </h2>
            {frontmatter.speedText && (
              <>
                <div className="text-center mt-10 pblogr">
                  <div
                    className="font-size-7 btn btn btn-dodger-blue-2 btn-small rounded-5 px-5 py-5"
                    tabIndex={0}
                    role="button"
                    onKeyDown={() => {
                      setShow(true);
                    }}
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    Click here to read it at 300 words per min
                  </div>
                </div>
                {isShowing && (
                  <div className="speedread relative">
                    <SpeedyReader
                      ref={speed}
                      speed="300"
                      inputText={frontmatter.speedText}
                    />

                    <div className="controls flex w-100 justify-content-center">
                      {isPlaying ? (
                        <button
                          className="mx-5 btn btn btn-dodger-blue-2 btn-small rounded-5 font-size-3"
                          onClick={() => {
                            speed.current.pause();
                            setPlaying(false);
                          }}
                        >
                          Pause
                        </button>
                      ) : (
                        <button
                          className="mx-5 btn btn btn-dodger-blue-2 btn-small rounded-5 font-size-3"
                          onClick={() => {
                            speed.current.play();
                            setPlaying(true);
                          }}
                        >
                          Start
                        </button>
                      )}
                      <button
                        className="mx-5 btn btn btn-dodger-blue-2 btn-small rounded-5 font-size-3"
                        onClick={() => {
                          speed.current.reset();
                        }}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </div>
      <div style={{ overflow: 'hidden', background: '#f7f9fc' }}>
          <Commit />
        </div>    </PageWrapper>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        videoplayer
        speedText
        demoTitle
        demoId
        description
        featureimage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`;
